import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { throwError } from 'rxjs';
import { FacultyService } from '../shared/services/faculty.service';

@Component({
  selector: 'app-faculty-detail',
  templateUrl: './faculty-detail.component.html',
  styleUrls: ['./faculty-detail.component.scss'],
})
export class FacultyDetailComponent implements OnInit {
  public faculty!: any;
  public facultyFAQS!: any;
  limit: number = 10; // <==== Edit this number to limit API results
  customOptions: OwlOptions = {
    loop: true,
    autoplay: false,
    center: true,
    autoHeight: false,
    autoWidth: true,
    mouseDrag: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private facultyService: FacultyService,
    private router: Router
  ) {
    this.activatedRoute.params.subscribe((v) => {
      if (v.id) {
        this.facultyService.getFaculty(v.id).subscribe(
          (res) => {
            if (res.data && res.data.getFaculty.id) {
              this.faculty = res.data.getFaculty;
              this.faculty.content = decodeURI(res.data.getFaculty.content);
              this.faculty.faqs.items.map((faq: any) => {
                try {
                  faq.faq.response = decodeURI(faq.faq.response);
                } catch (error) {}
              });
              this.facultyFAQS = this.faculty.faqs.items;
            }
          },
          (err) => {
            console.log(err);
          }
        );
      }
    });
  }

  ngOnInit(): void {}

  prev($event: any): void {
    $event.prev();
  }

  next($event: any): void {
    $event.next();
  }

  onQuestionDetails(id: string) {
    const url = this.router.createUrlTree(['/q&a', id]);
    window.open(url.toString(), '_blank');
  }

  doFilter($event: any) {
    this.faculty.faqs.items = this.facultyFAQS;
    if ($event == null) {
      this.faculty.faqs.items = this.facultyFAQS;
    } else {
      let filteredFAQS: any[] = [];
      if ($event.selectedCategory) {
        filteredFAQS = this.faculty.faqs.items.filter((faq: any) => {
          const faqCategories = faq.faq.categories.items;
          return faqCategories.some(
            (category: any) => category.category.id === $event.selectedCategory
          );
        });
      }
      this.faculty.faqs.items = filteredFAQS;
    }
  }
}
