<div class="xl:p-16">
  <div class="w-full xl:w-1/2 xl:mx-auto px-8 py-8 xl:p-0">
    <app-search-input [showSuggestions]="true"></app-search-input>
  </div>
  <div class="w-full xl:w-1/2 xl:mx-auto px-8 xl:p-0">
    <app-filter (doFilter)="doFilter($event)"></app-filter>
  </div>
  <div
    *ngIf="searchResult.length === 0 && searchFinished"
    class="
      w-full
      xl:w-1/2 xl:mx-auto
      my-4
      p-4
      rounded-xl
      bg-engie-light-blue
      select-none
      cursor-pointer
    "
  >
    <p class="text-lg font-bold text-engie-blue text-center m-0">
      Aucun résultat ne correspond à votre recherche.
    </p>
  </div>
  <div
    class="
      w-full
      xl:w-1/2 xl:mx-auto
      my-4
      p-4
      xl:rounded-xl
      bg-engie-light-blue
      select-none
      cursor-pointer
    "
    *ngFor="let faq of searchResult"
    (click)="onQuestionDetails(faq.id)"
  >
    <div
      class="text-lg font-bold text-engie-blue text-justify"
      [innerHtml]="faq.question"
    ></div>
    <div
      class="
        text-md text-justify text-engie-light-grey text-indent
        h-32
        overflow-hidden
      "
      [innerHtml]="faq.response"
    ></div>
    <div class="text-md text-justify text-engie-light-grey text-indent">
      ...
    </div>
    <div class="block md:flex items-center my-2">
      <div
        class="
          bg-engie-blue
          rounded-full
          flex
          my-2
          md:my-0 md:mx-0
          w-max
          mx-2
          justify-center
          md:w-auto
        "
      >
        <div *ngFor="let facultyItem of faq.faculties.items">
          <div [ngSwitch]="facultyItem.faculty.title">
            <img
              *ngSwitchCase="'Solaire'"
              src="assets/png/pictos_solaire.png"
              class="h-8 bg-engie-blue rounded-full"
            />
            <img
              *ngSwitchCase="'Hydroélectricité'"
              src="assets/png/pictos_hydroelectricite.png"
              class="h-8 bg-engie-blue rounded-full"
            />
            <img
              *ngSwitchCase="'Éolien'"
              src="assets/png/pictos_eolien.png"
              class="h-8 bg-engie-blue rounded-full"
            />
            <img
              *ngSwitchCase="'Biogaz'"
              src="assets/png/pictos_biogaz.png"
              class="h-8 bg-engie-blue rounded-full"
            />
          </div>
        </div>
      </div>

      <p
        class="
          text-xs
          py-1
          px-2
          bg-engie-blue
          text-white text-center
          rounded-full
          w-max
          h-6
          m-0
          mx-2
          my-2
          md:my-0
        "
        *ngFor="let categoryItem of faq.categories.items"
      >
        #{{ categoryItem.category.name.slice(0, 30) }}
        {{ categoryItem.category.name.length > 30 ? "..." : "" }}
      </p>
    </div>
  </div>
</div>
