<div class="xl:p-16 py-8 px-8" *ngIf="questionResponse">
  <div class="w-full xl:w-1/2 xl:mx-auto my-12 px-8 xl:px-0">
    <div
      class="text-xl font-bold text-engie-blue text-justify"
      [innerHtml]="questionResponse.question"
    ></div>
    <div class="block md:flex items-center my-2">
      <div
        class="
          bg-engie-blue
          rounded-full
          flex
          my-2
          md:my-0 md:mx-0
          w-max
          mr-2
          justify-center
          md:w-auto
        "
      >
        <div *ngFor="let facultyItem of questionResponse.faculties.items">
          <div [ngSwitch]="facultyItem.faculty.title">
            <img
              *ngSwitchCase="'Solaire'"
              src="assets/png/pictos_solaire.png"
              class="h-8 bg-engie-blue rounded-full"
            />
            <img
              *ngSwitchCase="'Hydroélectricité'"
              src="assets/png/pictos_hydroelectricite.png"
              class="h-8 bg-engie-blue rounded-full"
            />
            <img
              *ngSwitchCase="'Éolien'"
              src="assets/png/pictos_eolien.png"
              class="h-8 bg-engie-blue rounded-full"
            />
            <img
              *ngSwitchCase="'Biogaz'"
              src="assets/png/pictos_biogaz.png"
              class="h-8 bg-engie-blue rounded-full"
            />
          </div>
        </div>
      </div>

      <p
        class="
          text-xs
          py-1
          px-2
          bg-engie-blue
          text-white text-center
          rounded-full
          w-max
          h-6
          m-0
          md:mx-2
          my-2
          md:my-0
        "
        *ngFor="let categoryItem of questionResponse.categories.items"
      >
        #{{ categoryItem.category.name.slice(0, 30) }}
        {{ categoryItem.category.name.length > 30 ? "..." : "" }}
      </p>
    </div>
    <div class="text-justify" [innerHtml]="questionResponse.response"></div>
  </div>
</div>
