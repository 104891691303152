import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { SidenavService } from '../shared/services/sidenav.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @ViewChild('sidenav', { static: true })
  public sidenav!: MatSidenav;
  public isLargeScreen: Boolean = false;
  constructor(public router: Router, private sideNavService: SidenavService) {}

  ngOnInit() {
    this.sideNavService.sideNavToggleSubject.subscribe(() => {
      this.sidenav.toggle();
    });
    if (window.screen.width >= 1024) {
      this.isLargeScreen = true;
      this.sidenav.open();
    } else {
      this.isLargeScreen = false;
      this.sidenav.close();
    }
    if (this.router.url == '/') {
      // TODO rediriger vers la page unauthorized ou home
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: number } }) {
    if (event.target.innerWidth >= 1024) {
      this.isLargeScreen = true;
      //this.sidenav.open();
    } else {
      this.isLargeScreen = false;
      //this.sidenav.close();
    }
  }
}
