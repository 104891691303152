import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QuestionResponseService } from '../shared/services/question-response.service';

@Component({
  selector: 'app-question-response-details',
  templateUrl: './question-response-details.component.html',
  styleUrls: ['./question-response-details.component.scss'],
})
export class QuestionResponseDetailsComponent implements OnInit {
  public questionResponse!: any;
  private searchInput!: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private questionResponseService: QuestionResponseService
  ) {
    this.activatedRoute.queryParams.subscribe((p) => {
      if (p.keywords && p.keywords !== '') {
        this.searchInput = p.keywords.split(/-| /);
        this.activatedRoute.params.subscribe((v) => {
          if (v.id) {
            this.questionResponseService.getFAQ(v.id).subscribe(
              (res) => {
                if (res.data && res.data.getFAQ.id) {
                  this.questionResponse = res.data.getFAQ;
                  let colorResponseOccurences = this.questionResponse.response;
                  try {
                    colorResponseOccurences = decodeURI(
                      this.questionResponse.response
                    );
                  } catch (error) {}
                  this.questionResponse.response =
                    this.keywordResponseColorReplacement(
                      colorResponseOccurences
                    );
                  let colorQuestionOccurences = this.questionResponse.question;
                  this.questionResponse.question =
                    this.keywordQuestionColorReplacement(
                      colorQuestionOccurences
                    );
                }
              },
              (err) => {
                console.log(err);
              }
            );
          }
        });
      } else {
        this.activatedRoute.params.subscribe((v) => {
          if (v.id) {
            this.questionResponseService.getFAQ(v.id).subscribe(
              (res) => {
                if (res.data && res.data.getFAQ.id) {
                  this.questionResponse = res.data.getFAQ;
                  try {
                    this.questionResponse.response = decodeURI(
                      this.questionResponse.response
                    );
                  } catch (error) {}
                }
              },
              (err) => {
                console.log(err);
              }
            );
          }
        });
      }
    });
  }

  keywordQuestionColorReplacement(colorOccurences: any) {
    this.searchInput.forEach((keyword: string) => {
      if (keyword != '' && keyword != ' ' && keyword != '?') {
        var re = new RegExp('(?:^|\\s)' + keyword + '(?:$|\\s)', 'gi');
        const found = colorOccurences.match(re);
        found?.forEach((element: any) => {
          colorOccurences = colorOccurences.replaceAll(
            element,
            ' <span class="text-gray-600">' +
              element.replace(' ', '') +
              '</span> '
          );
        });
      }
      if (keyword.replace(' ', '') == '?') {
        colorOccurences = colorOccurences.replaceAll(
          '?',
          ' <span class="text-gray-600">?</span> '
        );
      }
    });
    return colorOccurences;
  }

  keywordResponseColorReplacement(colorOccurences: any) {
    this.searchInput.forEach((keyword: string) => {
      if (keyword != '' && keyword != ' ' && keyword != '?') {
        var re = new RegExp('(?:^|\\s)' + keyword + '(?:$|\\s)', 'gi');
        const found = colorOccurences.match(re);
        found?.forEach((element: any) => {
          colorOccurences = colorOccurences.replaceAll(
            element,
            ' <span class="text-engie-blue">' +
              element.replace(' ', '') +
              '</span> '
          );
        });
      }
      if (keyword.replace(' ', '') == '?') {
        colorOccurences = colorOccurences.replaceAll(
          '?',
          ' <span class="text-engie-blue">?</span> '
        );
      }
    });
    return colorOccurences;
  }

  ngOnInit(): void {}
}
