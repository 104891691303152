import { QuestionResponseDetailsComponent } from './question-response-details/question-response-details.component';
import { FacultyDetailComponent } from './faculty-detail/faculty-detail.component';
import { SearchResultComponent } from './search-result/search-result.component';
import { HomepageComponent } from './homepage/homepage.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: HomepageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'search',
        component: SearchResultComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'faculty/:id',
        component: FacultyDetailComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'q&a/:id',
        component: QuestionResponseDetailsComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
