<div class="xl:p-16 pt-8">
  <p
    class="text-justify w-full xl:w-1/2 lg:mx-auto my-4 px-8 xl:px-0"
    [innerHtml]="primaryText"
  ></p>
  <div class="w-full xl:w-1/2 xl:mx-auto my-12 px-8 xl:px-0">
    <app-search-input [showSuggestions]="true"></app-search-input>
  </div>
  <div
    class="w-full xl:w-1/2 xl:mx-auto my-4 p-4 bg-engie-light-blue rounded-xl"
  >
    <p class="text-engie-dark-grey text-center font-bold text-lg">
      NOS FILIÈRES
    </p>

    <div class="grid grid-cols-2 sm:flex sm:justify-center">
      <div
        class="cursor-pointer select-none sm:w-24"
        *ngFor="let faculty of faculties"
        (click)="onFacultyDetails(faculty.id)"
      >
        <div class="bg-engie-blue w-14 h-14 rounded-full mx-auto">
          <img class="" [src]="'assets/png/' + faculty.icon" />
        </div>
        <p class="text-xs mt-2 text-engie-blue text-center">
          {{ faculty.title }}
        </p>
      </div>
    </div>
    <div class="text-center my-4 md:px-24" [innerHtml]="facultiesText"></div>
  </div>
</div>
