import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class QuestionResponseService {
  constructor(public http: HttpClient) {}

  public getFAQ(id: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    const body = {
      query: `
      query{
        getFAQ(id:"${id}") { 
          id 
          question
          response
          faculties{items{faculty{id title}}}
          categories{items{category{id name}}}
        }
      }
    `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public listFAQS(): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    const body = {
      query: `
      query{
        listFAQS{items{
          id
          question
          response
          faculties{items{faculty{id title}}}
          categories{items{category{id name}}}
        }}
      }
    `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }
}
