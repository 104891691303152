// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appsyncBaseUrl:
    'https://3tu6qtnrejc25o5qn6aof6eouu.appsync-api.eu-west-1.amazonaws.com/graphql',
  appsyncAapiKey: 'da2-243i2yw7sffofnadhzadhdvwzm',
  encryptedPassword: 'U2FsdGVkX18fM5gPFwjDhZAVlDh3kycKvaFymh+Rr10=',
  secretKey: 'HwQ47kqA4Ss6FE9nM2N8AbUn68hsZhPoXUzvEbFWrc4yJXmCesTPaHYQz7HiBtwt',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
