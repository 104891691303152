<div class="flex items-center justify-center w-full h-10">
  <div class="w-full h-10">
    <div class="relative w-full h-10">
      <input
        type="text"
        name="q"
        class="
          py-2
          text-md text-engie-grey
          font-bold
          pr-10
          h-10
          pl-4
          w-full
          shadow-md
          ios-border
          focus:outline-none
        "
        (click)="selectAll($event)"
        [ngClass]="
          showSuggestions && suggestionResult.length > 0
            ? 'rounded-t-xl'
            : 'rounded-full'
        "
        placeholder="Rechercher"
        autocomplete="off"
        [(ngModel)]="searchInput"
        (ngModelChange)="onModelChange($event)"
        (keyup.enter)="onSearch()"
      />
      <span
        class="
          absolute
          inset-y-0
          right-0
          w-8
          h-8
          mt-1
          mr-1
          flex
          items-center
          justify-center
          rounded-full
          bg-engie-blue
        "
      >
        <button
          class="p-1 focus:outline-none focus:shadow-outline"
          (click)="onSearch()"
        >
          <img class="" src="assets/svg/search.svg" />
        </button>
      </span>
      <div
        *ngIf="showSuggestions && suggestionResult.length > 0"
        class="
          p-4
          bg-engie-blue
          absolute
          rounded-b-xl
          shadow-xl
          w-full
          text-white
          z-50
        "
      >
        <div
          *ngFor="let suggestion of suggestionResult"
          class="my-1 cursor-pointer"
          (click)="onSuggestionClick(suggestion)"
        >
          {{ suggestion }}
        </div>
      </div>
    </div>
  </div>
</div>
