import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { QuestionResponseService } from '../shared/services/question-response.service';
import { SearchService } from '../shared/services/search.service';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
})
export class SearchResultComponent implements OnInit {
  public searchInput!: any;
  public searchInputString!: string;
  public searchFinished: boolean = false;

  public searchResult: any = [];
  public searchResultWhenClear: any = [];
  public defaultCategoryFilter!: string;
  constructor(
    private route: ActivatedRoute,
    private searchService: SearchService,
    private router: Router,
    private questionResponseService: QuestionResponseService
  ) {
    this.route.queryParams.subscribe((p) => {
      if (p.q && p.q !== '') {
        this.searchInput = p.q.split(/-| /);
        this.searchInputString = p.q;
        this.lunchSearch();
      } else {
        this.searchInputString = '';
        this.listFAQS();
      }
      if (p.filter) {
        this.defaultCategoryFilter = p.filter;
      }
    });
  }

  ngOnInit(): void {}

  listFAQS(): void {
    this.searchResult = [];
    this.searchFinished = false;
    this.questionResponseService.listFAQS().subscribe(
      (response) => {
        if (response.data && response.data.listFAQS) {
          response.data.listFAQS.items.forEach((faq: any) => {
            try {
              faq.response = decodeURI(faq.response);
            } catch (error) {}
            this.searchResult.push(faq);
          });
          this.searchResultWhenClear = this.searchResult;
          if (this.defaultCategoryFilter) {
            this.doFilter({
              selectedCategory: this.defaultCategoryFilter,
            });
          }
        }
        this.searchFinished = true;
      },
      (error) => {
        this.searchFinished = true;
        console.log(error);
      }
    );
  }

  keywordQuestionColorReplacement(colorOccurences: any) {
    this.searchInput.forEach((keyword: string) => {
      if (keyword != '' && keyword != ' ' && keyword != '?') {
        var re = new RegExp('(?:^|\\s)' + keyword + '(?:$|\\s)', 'gi');
        const found = colorOccurences.match(re);
        found?.forEach((element: any) => {
          colorOccurences = colorOccurences.replaceAll(
            element,
            ' <span class="text-gray-600">' +
              element.replace(' ', '') +
              '</span> '
          );
        });
      }
      if (keyword.replace(' ', '') == '?') {
        colorOccurences = colorOccurences.replaceAll(
          '?',
          ' <span class="text-gray-600">?</span> '
        );
      }
    });
    return colorOccurences;
  }

  keywordResponseColorReplacement(colorOccurences: any) {
    this.searchInput.forEach((keyword: string) => {
      if (keyword != '' && keyword != ' ' && keyword != '?') {
        var re = new RegExp('(?:^|\\s)' + keyword + '(?:$|\\s)', 'gi');
        const found = colorOccurences.match(re);
        found?.forEach((element: any) => {
          colorOccurences = colorOccurences.replaceAll(
            element,
            ' <span class="text-engie-blue">' +
              element.replace(' ', '') +
              '</span> '
          );
        });
      }
      if (keyword.replace(' ', '') == '?') {
        colorOccurences = colorOccurences.replaceAll(
          '?',
          ' <span class="text-engie-blue">?</span> '
        );
      }
    });
    return colorOccurences;
  }

  lunchSearch(): void {
    this.searchResult = [];
    this.searchFinished = false;
    if (this.searchInput && this.searchInput !== '') {
      const searchFAQSObservable = this.searchService.searchFAQS(
        this.searchInput
      );
      const searchCategoriesObservable = this.searchService.searchCategories(
        this.searchInput
      );
      const searchFaculties = this.searchService.searchFaculties(
        this.searchInput
      );
      forkJoin([
        searchFAQSObservable,
        searchCategoriesObservable,
        searchFaculties,
      ]).subscribe(
        (res) => {
          let FAQSSearchResult: any[] = [];
          let CategoriesSearchResult: any[] = [];
          let FacultiesSearchResult: any[] = [];
          if (res[0] && res[0].data) {
            res[0].data.searchFAQS.items.forEach((faq: any) => {
              let colorResponseOccurences = faq.response;
              try {
                colorResponseOccurences = decodeURI(faq.response);
              } catch (error) {}
              faq.response = this.keywordResponseColorReplacement(
                colorResponseOccurences
              );
              let colorQuestionOccurences = faq.question;
              faq.question = this.keywordQuestionColorReplacement(
                colorQuestionOccurences
              );
              FAQSSearchResult.push(faq);
            });
          }
          if (res[1] && res[1].data.searchCategories) {
            res[1].data.searchCategories.items.forEach((category: any) => {
              category.faqs.items.forEach((faq: any) => {
                let colorResponseOccurences = faq.faq.response;
                try {
                  colorResponseOccurences = decodeURI(faq.faq.response);
                } catch (error) {}
                faq.faq.response = this.keywordResponseColorReplacement(
                  colorResponseOccurences
                );
                let colorQuestionOccurences = faq.faq.question;
                faq.faq.question = this.keywordQuestionColorReplacement(
                  colorQuestionOccurences
                );
                CategoriesSearchResult.push(faq.faq);
              });
            });
          }
          if (res[2] && res[2].data.searchFaculties) {
            res[2].data.searchFaculties.items.forEach((faculty: any) => {
              faculty.faqs.items.forEach((faq: any) => {
                let colorResponseOccurences = faq.faq.response;
                try {
                  colorResponseOccurences = decodeURI(faq.faq.response);
                } catch (error) {}
                faq.faq.response = this.keywordResponseColorReplacement(
                  colorResponseOccurences
                );
                let colorQuestionOccurences = faq.faq.question;
                faq.faq.question = this.keywordQuestionColorReplacement(
                  colorQuestionOccurences
                );
                FacultiesSearchResult.push(faq.faq);
              });
            });
          }
          this.searchResult.push(
            ...FAQSSearchResult,
            ...CategoriesSearchResult,
            ...FacultiesSearchResult
          );
          let unique = [
            ...new Map(
              this.searchResult.map((item: any) => [item.id, item])
            ).values(),
          ];

          let x = unique.sort((a: any, b: any) =>
            a.response.toLowerCase().split(this.searchInput[0].toLowerCase())
              .length -
              1 <
            b.response.toLowerCase().split(this.searchInput[0].toLowerCase())
              .length -
              1
              ? 1
              : -1
          );
          this.searchResult = x;
          this.searchResultWhenClear = x;
          this.searchFinished = true;
        },
        (err) => {
          this.searchFinished = true;
          console.log(err);
        }
      );
    }
  }

  doFilter($event: any) {
    if ($event == null) {
      this.searchInput && this.searchInput !== ''
        ? this.lunchSearch()
        : this.listFAQS();
    } else {
      let filteredFAQS: any[] = [];
      filteredFAQS = this.searchResultWhenClear.filter((faq: any) => {
        const faqCategories = faq.categories.items;

        const faqFaculties = faq.faculties.items;
        const catex = $event.selectedCategory
          ? faqCategories.some(
              (category: any) =>
                category.category.id === $event.selectedCategory
            )
          : true;
        const facultyex = $event.selectedFaculty
          ? faqFaculties.some(
              (faculty: any) => faculty.faculty.id === $event.selectedFaculty
            )
          : true;
        return catex && facultyex;
      });
      let x = filteredFAQS.sort((a: any, b: any) =>
        a.response.toLowerCase().split(this.searchInputString.toLowerCase())
          .length -
          1 <
        b.response.toLowerCase().split(this.searchInputString.toLowerCase())
          .length -
          1
          ? 1
          : -1
      );
      this.searchResult = x;
    }
  }

  onQuestionDetails(id: string) {
    const url = this.router.createUrlTree(['/q&a', id], {
      queryParams: { keywords: this.searchInputString },
    });
    window.open(url.toString(), '_blank');
  }
}
