<div class="header flex items-center mt-4">
  <div class="cursor-pointer mx-auto">
    <img src="assets/svg/logo.svg" (click)="goToHome()" />
  </div>
</div>
<div class="h-8 px-4">
  <app-search-input [closeOnSearch]="true"></app-search-input>
</div>
<div class="faculties-brief">
  <p class="text-engie-dark-grey text-center my-5 font-bold text-lg">
    NOS FILIÈRES
  </p>
  <div class="grid grid-cols-2 px-8">
    <div
      class="cursor-pointer select-none"
      *ngFor="let faculty of faculties"
      (click)="onFacultyDetails(faculty.id)"
    >
      <div class="bg-engie-blue w-14 h-14 rounded-full mx-auto">
        <img class="" [src]="'assets/png/' + faculty.icon" />
      </div>
      <p class="text-md mt-2 text-engie-dark-grey text-center">
        {{ faculty.title }}
      </p>
    </div>
  </div>
</div>

<div class="thematics">
  <p class="text-engie-dark-grey text-center my-4 font-bold text-lg">
    THÉMATIQUES
  </p>
  <p
    class="text-md mt-2 text-engie-blue text-center px-2 cursor-pointer"
    *ngFor="let category of categories"
    (click)="onSearch(category.id)"
  >
    {{ category.name }}
  </p>
</div>

<div class="contact w-full mb-8">
  <div class="h-auto">
    <p class="text-engie-dark-grey text-center font-bold text-lg underline">
      <a href="mailto:veille-enr@engie.com">CONTACT</a>
    </p>
    <div class="cursor-pointer mx-auto">
      <img src="assets/svg/engie-logo.svg" class="mx-auto" />
    </div>
  </div>
</div>
