import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { CategoryService } from '../../services/category.service';
import { FacultyService } from '../../services/faculty.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  public categories: any[] = [];
  public faculties: any[] = [];
  @Input() showFilters: boolean = false;
  @Input() autoriseMinimise: boolean = true;
  public selectedFaculty: any;
  public selectedCategory: any;
  @Input() showFaculties: boolean = true;
  @Input() defaultCategoryFilter!: string;

  @Output() doFilter: EventEmitter<any> = new EventEmitter();

  constructor(
    private categoryService: CategoryService,
    private facultyService: FacultyService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((p) => {
      if (p.filter) {
        this.defaultCategoryFilter = p.filter;
        this.selectedCategory = p.filter;
        this.showFilters = true;
      } else {
        this.selectedCategory = undefined;
      }
    });
  }

  ngOnInit(): void {
    this.getData();
  }

  onChangeCategory($event: any) {
    this.selectedCategory = $event.value;
    this.doFilter.emit({
      selectedCategory: this.selectedCategory,
      selectedFaculty: this.selectedFaculty,
    });
  }

  onChangeFaculty($event: any) {
    this.selectedFaculty = $event.value;
    this.doFilter.emit({
      selectedCategory: this.selectedCategory,
      selectedFaculty: this.selectedFaculty,
    });
  }

  onClearFilters() {
    this.selectedCategory = undefined;
    this.selectedFaculty = undefined;
    this.doFilter.emit(null);
  }

  onFaculty(id: string) {
    this.selectedFaculty = id;
    this.doFilter.emit({
      selectedCategory: this.selectedCategory,
      selectedFaculty: this.selectedFaculty,
    });
  }

  onShowFilters() {
    if (this.autoriseMinimise) {
      if (
        this.showFilters === true &&
        (this.selectedCategory || this.selectedFaculty)
      ) {
        this.showFilters = true;
      } else {
        this.showFilters = !this.showFilters;
      }
    }
  }

  getData(): void {
    const categoryObservable = this.categoryService.listCategories();
    const facultyObservable = this.facultyService.listFaculties();
    forkJoin([categoryObservable, facultyObservable]).subscribe(
      (res: any) => {
        if (res[0].data && res[0].data.listCategories) {
          this.categories = [];
          res[0].data.listCategories.items.forEach((element: any) => {
            this.categories.push(element);
          });
        }
        if (res[1].data && res[1].data.listFaculties) {
          this.faculties = [];
          res[1].data.listFaculties.items.forEach((element: any) => {
            switch (element.title) {
              case 'Solaire':
                element.icon = 'pictos_solaire.png';
                break;
              case 'Hydroélectricité':
                element.icon = 'pictos_hydroelectricite.png';
                break;
              case 'Éolien':
                element.icon = 'pictos_eolien.png';
                break;
              case 'Biogaz':
                element.icon = 'pictos_biogaz.png';
                break;
              default:
                break;
            }
            this.faculties.push(element);
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
