import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { SearchService } from '../../services/search.service';
import { SidenavService } from '../../services/sidenav.service';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent implements OnInit {
  public searchInput!: string;
  public suggestionResult: any = [];
  @Input() showSuggestions: boolean = false!;
  @Input() closeOnSearch: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private searchService: SearchService,
    private sidenavService: SidenavService
  ) {
    this.route.queryParams.subscribe((p) => {
      if (p.q && p.q !== '') {
        this.searchInput = p.q;
      }
    });
  }

  ngOnInit(): void {}

  selectAll($event: any) {
    $event.target.select();
  }

  onSearch(): void {
    this.showSuggestions = false;
    if (this.closeOnSearch) {
      this.sidenavService.toggle();
    }

    this.router.navigate(['/search'], {
      queryParams: { q: this.searchInput },
    });
  }

  onModelChange($event: any) {
    if ($event && $event.trim() != '') {
      const searchInput = $event.split(/-| /);
      this.getSuggestions(searchInput);
    } else {
      this.suggestionResult = [];
    }
  }

  getSuggestions(searchInput: any) {
    this.searchService.getFAQSSuggestions(searchInput).subscribe((results) => {
      if (results && results.data.searchFAQS) {
        this.suggestionResult = [];
        results.data.searchFAQS.items.forEach((faq: any) => {
          if (this.suggestionResult.length < 5) {
            this.suggestionResult.push(faq.question);
          }
        });
      }
    });
  }

  onSuggestionClick(suggestion: string) {
    this.showSuggestions = false;
    this.searchInput = suggestion;
    this.router.navigate(['/search'], {
      queryParams: { q: this.searchInput },
    });
  }
}
