<div class="relative">
  <div class="flex" *ngIf="!showFilters">
    <div
      class="
        bg-engie-blue
        text-white
        flex
        items-center
        w-20
        rounded-full
        my-2
        cursor-pointer
      "
      (click)="onShowFilters()"
    >
      <p class="text-center w-full p-0 m-0">Filtres</p>
    </div>
  </div>

  <div
    *ngIf="showFilters"
    class="
      bg-engie-blue
      text-white
      rounded-3xl
      my-2
      p-3
      w-max
      block
      md:flex
      items-center
      justify-center
      relative
    "
  >
    <p
      class="text-left w-full p-0 m-0 cursor-pointer"
      (click)="onShowFilters()"
    >
      Filtres
    </p>
    <div class="w-full" *ngIf="showFaculties">
      <mat-select
        class="
          w-32
          h-8
          rounded-full
          outline-none
          text-engie-blue
          px-2
          text-center
          pt-1
          text-md
          bg-white
          my-2
          md:my-0 md:mx-2
        "
        placeholder="Filières"
        (selectionChange)="onChangeFaculty($event)"
        [(ngModel)]="selectedFaculty"
      >
        <mat-option
          class="text-engie-blue"
          *ngFor="let faculty of faculties"
          [value]="faculty.id"
          >{{ faculty.title }}</mat-option
        >
      </mat-select>
    </div>

    <div class="w-full">
      <mat-select
        class="
          w-32
          h-8
          rounded-full
          outline-none
          text-engie-blue
          px-2
          text-center
          pt-1
          text-md
          bg-white
          my-2
          md:my-0 md:mx-2
        "
        placeholder="Thématiques"
        (selectionChange)="onChangeCategory($event)"
        [(ngModel)]="selectedCategory"
      >
        <mat-option
          class="text-engie-blue"
          *ngFor="let category of categories"
          [value]="category.id"
          >{{ category.name }}</mat-option
        >
      </mat-select>
    </div>
    <div
      class="
        md:relative md:top-auto md:right-auto
        absolute
        top-0
        right-0
        cursor-pointer
      "
    >
      <button mat-icon-button fxShow.sm="true" fxShow.xs="true" fxShow="false">
        <mat-icon matTooltip="Effacer les filtres" (click)="onClearFilters()"
          >close</mat-icon
        >
      </button>
    </div>
  </div>
</div>
