<mat-toolbar
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="bg-engie-blue sticky top-0 z-50"
>
  <button
    mat-icon-button
    fxShow.sm="true"
    fxShow.xs="true"
    fxShow="false"
    class="absolute"
  >
    <img src="assets/svg/menu.svg" matTooltip="Menu" (click)="clickMenu()" />
  </button>
  <img
    src="assets/svg/logo-white.svg"
    class="mx-auto cursor-pointer"
    (click)="goToHome()"
  />
  <button
    mat-icon-button
    (click)="onLogout()"
    class="text-white"
    matTooltip="Déconnexion"
  >
    <mat-icon>exit_to_app</mat-icon>
  </button>
</mat-toolbar>
