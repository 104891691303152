import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { CategoryService } from '../shared/services/category.service';
import { FacultyService } from '../shared/services/faculty.service';
import { HomepageService } from '../shared/services/homepage.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
  public faculties: any[] = [];
  public primaryText!: string;
  public facultiesText!: string;

  constructor(
    private homepageService: HomepageService,
    private facultyService: FacultyService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  onFacultyDetails(id: string) {
    this.router.navigate(['/faculty', id]);
  }

  getData(): void {
    const homepageObservable = this.homepageService.listHomepageTexts();
    const facultyObservable = this.facultyService.listFaculties();
    forkJoin([homepageObservable, facultyObservable]).subscribe(
      (res: any) => {
        if (res[0].data && res[0].data.listHomepageTexts) {
          this.primaryText = res[0].data.listHomepageTexts.items[0].primary;
          this.facultiesText =
            res[0].data.listHomepageTexts.items[0].facultiesText;
        }
        if (res[1].data && res[1].data.listFaculties) {
          this.faculties = [];
          res[1].data.listFaculties.items.forEach((element: any) => {
            switch (element.title) {
              case 'Solaire':
                element.icon = 'pictos_solaire.png';
                break;
              case 'Hydroélectricité':
                element.icon = 'pictos_hydroelectricite.png';
                break;
              case 'Éolien':
                element.icon = 'pictos_eolien.png';
                break;
              case 'Biogaz':
                element.icon = 'pictos_biogaz.png';
                break;
              default:
                break;
            }
            this.faculties.push(element);
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
