import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FacultyService {
  constructor(public http: HttpClient) {}

  public listFaculties(): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    const body = {
      query: `
    query{
      listFaculties {items { id title content category{ id name } }}
    }
  `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public getFaculty(id: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    const body = {
      query: `
        query{
          getFaculty(id:"${id}") { 
            id 
            title 
            content 
            category { 
              id 
              name 
            }
            faqs {
              items {
                faq {
                  id 
                  question 
                  response
                  categories{items{category{id name}}}
                  faculties{items{faculty{id title}}}
                }
              }
            }
          }
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }
}
