import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(public http: HttpClient) {}

  public searchFAQS(query: any): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    let request = '';
    query.forEach((keyword: string) => {
      request += `{or: [
        { response: {  wildcard: "*${keyword.toLowerCase()}*" } },
        { question: { wildcard: "*${keyword.toLowerCase()}*" } }
      ]},`;
    });
    const body = {
      query: `
        query{
          searchFAQS(filter: {
            and:[
              ${request}
            ]
          }) {
            items {
              id
              question
              response
              categories{items{category{id name}}}
              faculties{items{faculty{id title}}}
            }
          }
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public getFAQSSuggestions(query: any): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    let request = '';
    query.forEach((keyword: string) => {
      request += `{question: {  wildcard: "*${keyword.toLowerCase()}*" }},,`;
    });
    const body = {
      query: `
        query{
          searchFAQS(filter: {
            and:[
              ${request}
            ]
          }) {
            items {
              id
              question
              response
              categories{items{category{id name}}}
              faculties{items{faculty{id title}}}
            }
          }
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public searchCategories(query: any): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    let request = '';
    query.forEach((keyword: string) => {
      request += `{name: {  wildcard: "*${keyword.toLowerCase()}*" }},`;
    });
    const body = {
      query: `
        query{
          searchCategories(filter: {
            and:[
              ${request}
            ]
          }) {
            items{
              faqs{
                items{
                  faq{
                    id
                    question
                    response
                    categories{
                      items{
                        category{
                          id
                          name
                        }
                      }
                    }
                    faculties{
                      items{
                        faculty{
                          id
                          title
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public searchFaculties(query: any): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    let request = '';
    query.forEach((keyword: string) => {
      request += `{title: {  wildcard: "*${keyword.toLowerCase()}*" }},`;
    });
    const body = {
      query: `
        query{
          searchFaculties(filter: {
            and:[
              ${request}
            ]
          }) {
            items{
              faqs{
                items{
                  faq{
                    id
                    question
                    response
                    categories{
                      items{
                        category{
                          id
                          name
                        }
                      }
                    }
                    faculties{
                      items{
                        faculty{
                          id
                          title
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }
}
