import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public password!: string;
  public isSubmitted: boolean = false;
  public isValid: boolean = false;
  constructor(private router: Router) {}

  ngOnInit() {}

  onModelChange($event: any) {
    this.isSubmitted = false;
    this.isValid = false;
  }

  onConnect() {
    this.isSubmitted = true;
    const decryptedPassword = CryptoJS.AES.decrypt(
      environment.encryptedPassword.trim(),
      environment.secretKey.trim()
    ).toString(CryptoJS.enc.Utf8);
    if (decryptedPassword === this.password) {
      this.isValid = true;
      localStorage.setItem('STATUS', 'CONNECTED');
      this.router.navigate(['']);
    } else {
      this.isValid = false;
    }
  }
}
