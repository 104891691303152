<mat-sidenav-container autosize>
  <mat-sidenav #sidenav [mode]="isLargeScreen ? 'side' : 'over'">
    <app-menu></app-menu>
  </mat-sidenav>
  <mat-sidenav-content class="bg-white">
    <app-header></app-header>
    <article>
      <router-outlet></router-outlet>
    </article>
  </mat-sidenav-content>
</mat-sidenav-container>
