<div class="xl:p-8 py-8" *ngIf="faculty">
  <div
    class="
      flex
      my-8
      bg-white
      items-center
      relative
      w-full
      px-8
      2xl:w-1/2 2xl:mx-auto
    "
  >
    <div class="absolute 2xl:right-0 right-8">
      <div [ngSwitch]="faculty.title">
        <img
          *ngSwitchCase="'Solaire'"
          src="assets/png/pictos_solaire.png"
          class="h-16 bg-engie-blue rounded-full"
        />
        <img
          *ngSwitchCase="'Hydroélectricité'"
          src="assets/png/pictos_hydroelectricite.png"
          class="h-16 bg-engie-blue rounded-full"
        />
        <img
          *ngSwitchCase="'Éolien'"
          src="assets/png/pictos_eolien.png"
          class="h-16 bg-engie-blue rounded-full"
        />
        <img
          *ngSwitchCase="'Biogaz'"
          src="assets/png/pictos_biogaz.png"
          class="h-16 bg-engie-blue rounded-full"
        />
      </div>
    </div>
    <div class="w-max">
      <div class="text-justify text-2xl font-bold text-engie-blue">
        {{ faculty.title }}
      </div>
    </div>
  </div>
  <div
    class="
      p-4
      md:p-8
      xl:p-16
      md:flex
      bg-engie-blue
      xl:rounded-2xl
      items-center
      relative
      w-full
      2xl:w-1/2 2xl:mx-auto
    "
  >
    <div class="w-full">
      <div class="text-white text-justify" [innerHtml]="faculty.content"></div>
    </div>
  </div>
  <div class="mt-8 w-full 2xl:w-1/2 2xl:mx-auto">
    <p class="text-center w-full text-3xl font-bold text-engie-blue">Q&A</p>
    <div class="mx-auto flex justify-center">
      <app-filter
        [showFaculties]="false"
        (doFilter)="doFilter($event)"
        [showFilters]="true"
        [autoriseMinimise]="false"
      ></app-filter>
    </div>
  </div>

  <div
    class="w-full 2xl:w-1/2 2xl:mx-auto select-none relative"
    *ngIf="faculty.faqs.items.length === 0"
  >
    <p class="text-lg font-bold text-engie-blue text-center m-0">
      Aucun résultat ne correspond à votre recherche.
    </p>
  </div>

  <div
    class="w-full 2xl:w-1/2 2xl:mx-auto select-none relative mb-16"
    *ngIf="faculty.faqs.items.length > 0"
  >
    <owl-carousel-o [options]="customOptions" #owlCar>
      <ng-container *ngFor="let faq of faculty.faqs.items">
        <ng-template class="slide" carouselSlide [id]="faq.faq.id">
          <div class="p-1">
            <div
              class="
                h-64
                p-4
                rounded-xl
                overflow-scroll
                shadow-md
                bg-engie-light-blue
              "
              (click)="onQuestionDetails(faq.faq.id)"
            >
              <p class="text-lg text-engie-blue font-bold text-justify">
                {{ faq.faq.question }}
              </p>
              <div
                class="text-justify text-engie-light-grey"
                [innerHtml]="faq.faq.response.slice(0, 300) + '...'"
              ></div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
    <div class="absolute top-50 z-50 -left-5 lg:block hidden cursor-pointer">
      <mat-icon matTooltip="Précédent" (click)="prev(owlCar)"
        >navigate_before</mat-icon
      >
    </div>
    <div class="absolute top-50 z-50 -right-5 lg:block hidden cursor-pointer">
      <mat-icon matTooltip="Suivant" (click)="next(owlCar)"
        >navigate_next</mat-icon
      >
    </div>
  </div>
</div>
