<div
  class="
    flex
    items-center
    justify-center
    w-full
    h-full
    bg-engie-blue
    lg:bg-white
  "
>
  <div class="w-full lg:w-1/2 xl:w-1/3 h-max bg-engie-blue lg:rounded-xl">
    <img src="assets/svg/logo-white.svg" class="mx-auto cursor-pointer my-8" />
    <div class="mx-auto md:w-1/2 w-full px-8 md:px-0 my-8">
      <input
        type="password"
        class="
          py-2
          text-md text-engie-grey
          font-bold
          h-10
          w-full
          shadow-md
          ios-border
          rounded-full
          px-4
          focus:outline-none
        "
        [(ngModel)]="password"
        (ngModelChange)="onModelChange($event)"
        (keyup.enter)="onConnect()"
        placeholder="Mot de passe"
      />
      <p
        class="py-2 text-md text-red-600 h-10 w-full px-4"
        *ngIf="isSubmitted && !isValid"
      >
        Mot de passe incorrect !
      </p>
    </div>
    <div class="mx-auto w-max my-8">
      <button
        mat-icon-button
        fxShow.sm="true"
        fxShow.xs="true"
        fxShow="false"
        class="bg-white rounded-full w-max px-4 text-engie-blue"
        (click)="onConnect()"
      >
        Connecter
        <mat-icon>navigate_next</mat-icon>
      </button>
    </div>
  </div>
</div>
