import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { CategoryService } from 'src/app/shared/services/category.service';
import { FacultyService } from 'src/app/shared/services/faculty.service';
import { SidenavService } from 'src/app/shared/services/sidenav.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  public categories: any[] = [];
  public faculties: any[] = [];

  constructor(
    private categoryService: CategoryService,
    private facultyService: FacultyService,
    private router: Router,
    private sidenavService: SidenavService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    const categoryObservable = this.categoryService.listCategories();
    const facultyObservable = this.facultyService.listFaculties();
    forkJoin([categoryObservable, facultyObservable]).subscribe(
      (res: any) => {
        if (res[0].data && res[0].data.listCategories) {
          this.categories = [];
          res[0].data.listCategories.items.forEach((element: any) => {
            this.categories.push(element);
          });
        }
        if (res[1].data && res[1].data.listFaculties) {
          this.faculties = [];
          res[1].data.listFaculties.items.forEach((element: any) => {
            switch (element.title) {
              case 'Solaire':
                element.icon = 'pictos_solaire.png';
                break;
              case 'Hydroélectricité':
                element.icon = 'pictos_hydroelectricite.png';
                break;
              case 'Éolien':
                element.icon = 'pictos_eolien.png';
                break;
              case 'Biogaz':
                element.icon = 'pictos_biogaz.png';
                break;
              default:
                break;
            }
            this.faculties.push(element);
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  goToHome() {
    if (window.screen.width < 1024) {
      this.sidenavService.toggle();
    }
    this.router.navigate(['/']);
  }

  onFacultyDetails(id: string) {
    if (window.screen.width < 1024) {
      this.sidenavService.toggle();
    }
    this.router.navigate(['/faculty', id]);
  }

  onSearch(categoryId: string): void {
    if (window.screen.width < 1024) {
      this.sidenavService.toggle();
    }
    this.router.navigate(['/search'], {
      queryParams: { filter: categoryId },
    });
  }
}
