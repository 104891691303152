import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from 'src/app/shared/modules/material-module';
import { MenuComponent } from './layout/menu/menu.component';
import { HeaderComponent } from './layout/header/header.component';
import { HomeComponent } from './home/home.component';
import { SearchInputComponent } from './shared/components/search-input/search-input.component';
import { HomepageComponent } from './homepage/homepage.component';
import { SearchResultComponent } from './search-result/search-result.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterComponent } from './shared/components/filter/filter.component';
import { FacultyDetailComponent } from './faculty-detail/faculty-detail.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { QuestionResponseDetailsComponent } from './question-response-details/question-response-details.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { LoginComponent } from './login/login.component';

@NgModule({
  declarations: [	
    AppComponent,
    HomeComponent,
    MenuComponent,
    HeaderComponent,
    SearchInputComponent,
    HomepageComponent,
    SearchResultComponent,
    FilterComponent,
    FacultyDetailComponent,
    QuestionResponseDetailsComponent,
      LoginComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    IvyCarouselModule,
    CarouselModule,
    ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
